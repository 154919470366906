<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <FinishedBanner v-if="finished"></FinishedBanner>
          <Score
            v-if="showScore"
            :star="math.star"
            :score="math.score"
            :difficulty="math.difficulty"
            :totalQ="math.noOfRounds"
            :countCorrect="math.countCorrect"
            :game="math.game"
            :topic="math.topic"
          ></Score>
          <XPDialog
            v-if="showXP && showScore"
            :callbackClose="() => (showXP = false)"
            :showDialog="true"
            :details="xpDetails"
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    FinishedBanner: () =>
      import(
        /* webpackChunkName: "component-mini-game-finished-banner" */ "@/components/games/FinishedBanner"
      ),
    Score: () =>
      import(
        /* webpackChunkName: "component-math-game-score" */ "@/components/games/MathScore"
      ),
    XPDialog: () =>
      import(
        /* webpackChunkName: "component-level-up-dialog" */ "@/components/XPDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    math: (state) => state.math.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    finished: true,
    showScore: false,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    showXP: false,
    xpDetails: {}, 
  }),
  async created() {
    if (!this.math.isSaved) {
      await this.savePlayerData();
      await this.spendEnergy();
      // if (!this.math.playerData) {
        await this.updateXP();
      // }
      let tempStore = this.$_.cloneDeep(this.math);
      tempStore["isSaved"] = true;
      this.$store.commit("updateMath", tempStore);
      setTimeout(() => {
        this.finished = false;
        this.showScore = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.finished = false;
        this.showScore = true;
      }, 3000);
    }
  },
  mounted() {
    //
  },
  methods: {
    async savePlayerData() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/math/record/save";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        schoolYear: this.math.schoolYear,
        chapterKey: this.math.chapterKey,
        questionKey: this.math.questionKey,
        grade:
          this.math.playerData && this.math.playerData.topStar > this.math.star
            ? this.math.playerData.grade
            : this.math.star == 3
            ? "A"
            : this.math.star == 2
            ? "B"
            : this.math.star == 1
            ? "C"
            : "F",
        topStar:
          this.math.playerData && this.math.playerData.topStar > this.math.star
            ? this.math.playerData.topStar
            : this.math.star,
        topScore:
          this.math.playerData &&
          this.math.playerData.topScore > this.math.score
            ? this.math.playerData.topScore
            : this.math.score,
        attempt: this.math.playerData ? this.math.playerData.attempt : 1,
        submission: this.math.playerData ? this.math.playerData.submission : 1,
      };
      await this.$api.fetch(this.api);
    },
    async spendEnergy() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/energy/spend";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.errorMessage = e;
        this.error = true;
      };
      this.api.callbackSuccess = async () => {
        this.api.isLoading = false;
      };
      this.api.params = { quantity: 1 };
      await this.$api.fetch(this.api);
    },
    async updateXP() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/xp/save";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.errorMessage = e;
        this.error = true;
      };
      this.api.callbackSuccess = async (resp) => {
        // if (resp.isLevelUp) {
          this.showXP = true;
          this.xpDetails = resp;
        // }
        this.api.isLoading = false;
      };
      this.api.params = {
        activityKey: "MATH_MASTERY",
      };
      await this.$api.fetch(this.api);
    },
    next() {
      this.$router.push({
        name: "PageMathMastery",
      });
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>